<!--
 * @Description: 服务单质检
 * @Author: ChenXueLin
 * @Date: 2021-09-16 11:15:00
 * @LastEditTime: 2022-08-25 15:36:12
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="inspectionId">
              <el-input
                v-model="searchForm.inspectionId"
                placeholder="质检ID"
                title="质检ID"
                @input="
                  searchForm.inspectionId = searchForm.inspectionId.replace(
                    /[^0-9-]+/,
                    ''
                  )
                "
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="serviceNo">
              <el-input
                v-model="searchForm.serviceNo"
                placeholder="服务单号"
                title="服务单号"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="corpName">
              <el-input
                v-model="searchForm.corpName"
                placeholder="客户名称"
                title="客户名称"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="contactName">
              <el-input
                v-model="searchForm.contactName"
                placeholder="来去电人名称"
                title="来去电人名称"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="contactPhone">
              <el-input
                v-model="searchForm.contactPhone"
                placeholder="联系电话"
                title="联系电话"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="sceneName">
              <el-input
                v-model="searchForm.sceneName"
                placeholder="场景名称"
                title="场景名称"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="createdBy">
              <el-input
                v-model="searchForm.createdBy"
                placeholder="工单创建人"
                title="工单创建人"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="solveName">
              <el-input
                v-model="searchForm.solveName"
                placeholder="工单处理人"
                title="工单处理人"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="QTStatus">
              <e6-vr-select
                v-model="searchForm.QTStatus"
                :data="qtStatusList"
                placeholder="质检状态"
                title="质检状态"
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="QTName">
              <el-input
                v-model="searchForm.QTName"
                placeholder="质检人"
                title="质检人"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="approveStatus">
              <e6-vr-select
                v-model="searchForm.approveStatus"
                :data="auditStatusList"
                placeholder="审核状态"
                title="审核状态"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="approveName">
              <el-input
                v-model="searchForm.approveName"
                placeholder="审核人"
                title="审核人"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--2" prop="createTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="工单创建时间"
                ref="effectiveDatePicker"
                v-model="searchForm.createTime"
                title="工单创建时间"
                :picker-options="pickerOptions('searchForm.createTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="工单创建时间（始）"
                end-placeholder="工单创建时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--2" prop="qtTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="质检时间"
                ref="effectiveDatePicker"
                v-model="searchForm.qtTime"
                title="质检时间"
                :picker-options="pickerOptions('searchForm.qtTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="质检时间（始）"
                end-placeholder="质检时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--2" prop="approveTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="审核时间"
                ref="effectiveDatePicker"
                v-model="searchForm.approveTime"
                title="审核时间"
                :picker-options="pickerOptions('searchForm.approveTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="审核时间（始）"
                end-placeholder="审核时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <i class="e6-icon-export_line" title="导出" @click="handleExport"></i>
        </template>
      </table-title>
      <!-- 搜索头部 end -->

      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          class="elTable"
          ref="elTable"
        >
          <el-table-column type="selection" width="40"></el-table-column>
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            fixed="right"
            align="center"
            label="操作"
            width="120"
          >
            <template slot-scope="scope">
              <e6-td-operate
                :data="getOperateList()"
                @command="handleOperate($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import { getServiceQTList, exportServiceQTList, findDownList } from "@/api";
import { printError } from "@/utils/util";
import { exportData } from "@/utils/download";
export default {
  name: "serviceQuality",
  components: {},
  data() {
    return {
      loading: false,
      total: 0,
      qtStatusList: [], //质检状态下拉框
      auditStatusList: [], //审核状态
      editDialog: false,
      editDialogLoading: false,
      searchForm: {
        inspectionId: "", //质检ID
        serviceNo: "", //服务单号
        corpName: "", //客户名称
        contactName: "", //联系人名称
        contactPhone: "", //联系电话
        sceneName: "", //场景名称
        createdBy: "", //工单创建人
        createTime: [],
        createdStartTime: "", //工单创建时间开始时间
        createdEndTime: "", //工单创建时间结束时间
        solveName: "", //工单处理人
        qtTime: [],
        QTStartTime: "", //质检时间开始时间
        QTEndTime: "", //质检结束时间
        QTStatus: "", //质检状态
        QTName: "", //质检人
        approveTime: [],
        approveStatus: "", //审核状态
        approveName: "", //审核人
        approveStartTime: "", //审核时间
        approveEndTime: "", //审核结束时间
        pageIndex: 1,
        pageSize: 20
      },
      columnData: [
        {
          fieldName: "inspectionId",
          display: true,
          fieldLabel: "质检ID",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "corpName",
          display: true,
          fieldLabel: "客户名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "contactName",
          display: true,
          fieldLabel: "联系人名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "contactPhone",
          display: true,
          fieldLabel: "联系电话",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "sceneName",
          display: true,
          fieldLabel: "场景名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "serviceNo",
          display: true,
          fieldLabel: "服务单号",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "serviceType",
          display: true,
          fieldLabel: "服务单类型",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createdBy",
          display: true,
          fieldLabel: "工单创建人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "workCreatedTimeStr",
          display: true,
          fieldLabel: "工单创建时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "workSource",
          display: true,
          fieldLabel: "工单来源",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "project",
          display: true,
          fieldLabel: "问题具体项目",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "replyLimit",
          display: true,
          fieldLabel: "标准回复时限（H）",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "replyTimeStr",
          display: true,
          fieldLabel: "要求首次回复时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "solveName",
          display: true,
          fieldLabel: "工单处理人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },

        {
          fieldName: "actualTimeStr",
          display: true,
          fieldLabel: "实际首次回复/处理时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "expactTimeStr",
          display: true,
          fieldLabel: "要求完成时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "workFinishTimeStr",
          display: true,
          fieldLabel: "工单完结时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "assignName",
          display: true,
          fieldLabel: "指派处理人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "assignTimeStr",
          display: true,
          fieldLabel: "任务交接时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "qTStatus",
          display: true,
          fieldLabel: "质检状态",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "qTSuggestion",
          display: true,
          fieldLabel: "质检建议",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "qTName",
          display: true,
          fieldLabel: "质检人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "qTTimeStr",
          display: true,
          fieldLabel: "质检时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "approveStatus",
          display: true,
          fieldLabel: "审核状态",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "approveName",
          display: true,
          fieldLabel: "审核人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "approveTimeStr",
          display: true,
          fieldLabel: "审核时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "approveSuggestion",
          display: true,
          fieldLabel: "审核建议",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [],
      addUserVisible: false,
      addUserForm: {},
      editCustomerDialogLoading: false,
      addUserRules: {}
    };
  },
  mixins: [listPage, base, listPageReszie],
  computed: {},
  watch: {
    // 创建时间
    "searchForm.createTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.createTime = this.searchForm.createTime.reverse();
        }
        this.searchForm.createdStartTime = val[0] || "";
        this.searchForm.createdEndTime = val[1] || "";
      }
    },
    //质检时间
    "searchForm.qtTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.qtTime = this.searchForm.qtTime.reverse();
        }
        this.searchForm.QTStartTime = val[0] || "";
        this.searchForm.QTEndTime = val[1] || "";
      }
    },
    //审核时间
    "searchForm.approveTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.approveTime = this.searchForm.approveTime.reverse();
        }
        this.searchForm.approveStartTime = val[0] || "";
        this.searchForm.approveEndTime = val[1] || "";
      }
    }
  },
  created() {
    this.initData();
    this.queryList();
  },
  mounted() {
    this.setTableScrollDOM("elTable");
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    async initData() {
      try {
        this.selectLoading = true;
        let promiseList = [findDownList(["isQTStatus", "isCheckStatus"])];
        let [statusRes] = await Promise.all(promiseList);
        //质检状态下拉框
        this.qtStatusList = this.getFreezeResponse(statusRes, {
          path: "data.isQTStatus"
        });
        //审核状态下拉框
        this.auditStatusList = this.getFreezeResponse(statusRes, {
          path: "data.isCheckStatus"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.selectLoading = false;
      }
    },
    //查询服务单质检列表
    async queryList() {
      try {
        this.loading = true;
        let res = await getServiceQTList(this.searchForm);
        this.tableData = this.getFreezeResponse(res, {
          path: "data.array"
        });
        this.total = this.getFreezeResponse(res, {
          path: "data.totalRecords"
        });
        this.$nextTick(() => {
          this.$refs.elTable.doLayout();
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 导出
    handleExport() {
      exportData(this, exportServiceQTList);
    },
    // 获取页面操作权限
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "详情",
          id: 1
        }
      ];
      return operates;
      // return this.filterOperateBtn(operates);
    },
    // 操作--更多
    handleOperate(val, row) {
      if (val.id == 1) {
        this.routerPush({
          name: "qualityInspectionCenter/serviceOrderDetail",
          params: {
            refresh: true,
            id: row.inspectionId,
            origin: this.$route.name
          }
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ .edit-customer-dialog {
  .el-form {
    padding: 20px 20px 0;
    .el-input {
      width: 350px;
    }
  }
}
</style>
